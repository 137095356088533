import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './postsList.styl'

import PostTeaser from '../postTeaser/'
import FrontPlaceholder from '../frontPlaceholder'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const POSTS_QUERY = gql`
	{
		getPosts {
			id
			created
			title
			slug
			description
			image
		}
	}
`


class PostsList extends Component {
	componentDidMount(){

	}
	render() {
		return (
			<article className="posts">
				<div className="container">
					<Query query={POSTS_QUERY}>
						{({ loading, error, data }) => {
							if (loading) return(
								<>
									<FrontPlaceholder />
									<FrontPlaceholder />
								</>
							)
							if (error) return <div>Error</div>

							return (
								<ul className="posts__list">
									{data.getPosts.map((post) => {
										return(
											<li className="posts__item" key={post.id}>
												<Link className="posts__link" to={post.slug} aria-label={post.title}>
													<PostTeaser
														title={post.title}
														img={post.image}
														description={post.description}
														created={post.created}
														front={true}
													/>
												</Link>
											</li>
										)
									})}
								</ul>
							)
						}}
					</Query>
				</div>
			</article>
		)
	}
}

export default PostsList

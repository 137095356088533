import React from 'react';
import ReactDOM from 'react-dom';
// import { render } from 'react-snapshot';
import Root from './root';
import { BrowserRouter } from 'react-router-dom';

import './styles/styles.styl';

import { API } from './helpers/const.js';

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
	gtmId: 'GTM-T9VDJ24'
}

TagManager.initialize(tagManagerArgs)

const httpLink = createHttpLink({
	uri: API
})

const client = new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache()
})

ReactDOM.hydrate(
	<ApolloProvider client={client}>
		<BrowserRouter>
			<Root />
		</BrowserRouter>
	</ApolloProvider>,
	document.getElementById('app')
);

if (module.hot) {
	module.hot.accept();
}

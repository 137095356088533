import React, { Component } from 'react';

import IconTwitter from './IconTwitter.svg'
import IconFb from './IconFb.svg'
import IconVk from './IconVk.svg'
import IconOk from './IconOk.svg'
import IconLinkedin from './IconLinkedin.svg'
import IconWhatsapp from './IconWhatsapp.svg'
import IconTelegram from './IconTelegram.svg'
import IconReddit from './IconReddit.svg'

import './share.styl'

function Share() {
	if(typeof window !== 'undefined'){
		var fullURL = document.location.href
	} else {
		var fullURL = global.__getUrl
	}

	return (
		<section className="share">
			<h3 className="visually-hidden">Поделиться</h3>
			<a
				href={`https://twitter.com/intent/tweet?url=${fullURL}`}
				className="share__btn"
				aria-label="Поделиться в твиттере"
				target="_blank"
			>
				<IconTwitter width="24" height="24" />
			</a>
			<a
				href={`https://www.facebook.com/sharer.php?src=sp&u=${fullURL}`}
				className="share__btn"
				aria-label="Поделиться в фейсбуке"
				target="_blank"
			>
				<IconFb width="24" height="24" />
			</a>
			<a
				href={`https://vk.com/share.php?url=${fullURL}`}
				className="share__btn"
				aria-label="Поделиться вконтакте"
				target="_blank"
			>
				<IconVk width="24" height="24" />
			</a>
			<a
				href={`https://connect.ok.ru/offer?url=${fullURL}`}
				className="share__btn"
				aria-label="Поделиться в одноклассниках"
				target="_blank"
			>
				<IconOk width="24" height="24" />
			</a>
			<a
				href={`https://www.linkedin.com/shareArticle?mini=true&url=${fullURL}`}
				className="share__btn"
				aria-label="Поделиться на линкедине"
				target="_blank"
			>
				<IconLinkedin width="24" height="24" />
			</a>
			<a
				href={`https://api.whatsapp.com/send?text=${fullURL}`}
				className="share__btn"
				aria-label="Поделиться в вотсаппе"
				target="_blank"
			>
				<IconWhatsapp width="24" height="24" />
			</a>
			<a
				href={`tg://msg_url?url=${fullURL}`}
				className="share__btn"
				aria-label="Поделиться в телеграме"
				target="_blank"
			>
				<IconTelegram width="24" height="24" />
			</a>
			<a
				href={`https://www.reddit.com/submit?url=${fullURL}`}
				className="share__btn"
				aria-label="Поделиться на реддите"
				target="_blank"
			>
				<IconReddit width="24" height="24" />
			</a>
		</section>
	)
}

export default Share

import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './header.styl'

import Logo from './logo.svg';

class Header extends Component {
	componentDidMount(){

	}
	render() {
		return (
			<header className="header">
				<Link to="/" aria-label="Главная" className="header__logo">
					<Logo width="100" height="100" />
				</Link>
			</header>
		)
	}
}

export default Header

import React, { Component } from 'react';

import './postPlaceholder.styl'

function PostPlaceholder(){
	return (
		<div className="postPlaceholder">
			<div className="postPlaceholder__title"></div>
			<div className="postPlaceholder__date"></div>
			<div className="postPlaceholder__contentBlock"></div>
			<div className="postPlaceholder__contentBlock"></div>
		</div>
	)
}

export default PostPlaceholder

import React, { Component } from 'react';

import { getDate } from '../../helpers/utils'

import './comments.styl'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const POST_COMMENTS_QUERY = gql`
	query ($url: String!) {
		getComments(postUrl: $url) {
			id
			created
			name
			text
		}
	}
`

class comments extends Component {
	componentDidMount(){

	}
	render() {
		return (
			<section className="comments">
				<Query query={POST_COMMENTS_QUERY} variables={{'url': this.props.url}} >
					{({ loading, error, data }) => {
						if (loading) return <div>Загрузка комментариев</div>
						if (error) return <div>Ошибка загрузки комментариев</div>

						if(!data.getComments.length) return <div className="comments__empty">Комментариев ещё нет. Оставьте первый!</div>

						return(
							<>
								{data.getComments.map((comment) => {
									let date = getDate(comment.created)
									return (
										<section key={comment.id} className="comments__item">
											<time className="comments__time" dateTime={date.dateTime}>{date.dateLocal}</time>
											<div className="comments__wrapper">
												<div className="comments__name">{comment.name}:</div>
												<div className="comments__text">{comment.text}</div>
											</div>
										</section>
									)
								})}
							</>
						)
					}}
				</Query>
			</section>
		)
	}
}

export default comments

function getDate(str){
	let date = new Date(str)

	let dateLocal = date.toLocaleDateString("ru-RU", {
		year: "numeric",
		month: "long",
		day: "2-digit",
	})

	let dateTime = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2)
	dateTime += "T" + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2);

	return {
		dateLocal,
		dateTime
	}
}

export { getDate }

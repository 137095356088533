import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './footer.styl'


class Footer extends Component {
	componentDidMount(){

	}
	render() {
		return (
			<footer className="footer">
				<div className="container footer__container">
					<a className="footer__link" href="mailto:beaver@webbeaver.ru">E-mail</a>
					<a className="footer__link" target="_blank" href="https://github.com/DoberBober">GitHub</a>
					<a className="footer__link footer__link--rss" href="#">RSS</a>
				</div>
			</footer>
		)
	}
}

export default Footer

import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './e404.styl'


class E404 extends Component {
	render() {
		return (
			<div className="e404">
				4̸̞̰̯̌̓̅̾͋̓̆͛̒͝0̸̧̣̪̜͋͗͘͝4̸̭͍̳̤̖̈́͋̽́̀̽
			</div>
		)
	}
}

export default E404

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from '../header/';
import Footer from '../footer/';

import routes from '../../helpers/routes'

import {Helmet} from "react-helmet"
import { SITE_URL, SITE_NAME, SOCIAL_IMAGE } from '../../helpers/const'

class App extends React.Component {
	componentDidMount(){

	}
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{`${SITE_NAME}`}</title>
					<meta name="twitter:title" content={`${SITE_NAME}`} />
					<meta name="og:title" content={`${SITE_NAME}`} />

					<meta name="twitter:image:src" content={SITE_URL + SOCIAL_IMAGE} />
					<meta name="og:image" content={SITE_URL + SOCIAL_IMAGE} />
				</Helmet>


				<Header />
				<main className="content">
					<Switch>
						{routes.map(route => {
							return <Route key={route.name} {...route} />
						})}
					</Switch>
				</main>
				<Footer />
			</React.Fragment>
		)
	}
}

export default App

import React from 'react';
import {Helmet} from "react-helmet"
import { SITE_DESCRIPTION } from './const'

import PostsList from '../components/postsList/';
import Post from '../components/post/';

const routes = [
	{
		path: '/',
		name: 'postsList',
		exact: true,
		component: (props) => (
			<React.Fragment>
				<Helmet>
					<meta name="description" content={`${SITE_DESCRIPTION}`} />
					<meta name="twitter:description" content={`${SITE_DESCRIPTION}`} />
					<meta name="og:description" content={`${SITE_DESCRIPTION}`} />
				</Helmet>
				<PostsList />
			</React.Fragment>
		)
	},
	{
		path: '/:slug',
		name: 'post',
		component: (props) => (
			<Post url={props.match != null ? props.match.params.slug : ""} />
		)
	},
];

export default routes;

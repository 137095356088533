import React, { Component } from 'react';

import './frontPlaceholder.styl'

function FrontPlaceholder(){
	return (
		<div className="frontPlaceholder">
			<div className="frontPlaceholder__img"></div>
			<div className="frontPlaceholder__teaser">
				<div className="frontPlaceholder__title"></div>
				<div className="frontPlaceholder__date"></div>
				<div className="frontPlaceholder__desc"></div>
			</div>
		</div>
	)
}

export default FrontPlaceholder

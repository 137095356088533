import React, { Component, useEffect } from 'react';
import { Link } from "react-router-dom";

import './post.styl'

import PostTeaser from '../postTeaser'
import CommentAndShare from '../commentAndShare'
import E404 from '../e404'
import PostPlaceholder from '../postPlaceholder'

import { PrismCode } from '../Prism'
import AdSense from 'react-adsense';

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import TranslateIcon from './translate.svg'
import UpdateIcon from './update.svg'
import TldrIcon from './tldr.svg'
import ConclusionIcon from './summary.svg'

import DemoIcon from './demo.svg'
import GithubIcon from './github.svg'

import {Helmet} from "react-helmet"
import { MEDIA_SRC, SITE_NAME_SHORT, SITE_URL, SOCIAL_IMAGE } from '../../helpers/const'

const POST_QUERY = gql`
	query ($url: String!) {
		getPost(slug: $url) {
			id
			created
			title
			image
			translate
			upd
			tldr
			conclusion
			demo
			github
			postContent {
				id
				heading
				text
				code
				codeType
			}
			seoTitle
			seoDescription
			additionalJS
			additionalCSS
			postExtralibrary {
				url
			}
		}
	}
`


function Post(props){
	if (!props.url) return null

	// function openLightbox(target){
	// 	console.log("Когда-нибудь.")
	// }

	useEffect(() => {
		if(typeof window !== 'undefined'){
			window.scrollTo({left: 0, top: 0, behavior: 'auto'})

			// let lightboxEventListener = (evt) => {
			// 	if(evt.target.tagName === "A" || evt.target.closest('.imgContainer')){
			// 		evt.preventDefault()
			// 		if(evt.target.tagName === "A"){
			// 			openLightbox(evt.target)
			// 		} else if (evt.target.closest('.imgContainer')){
			// 			openLightbox(evt.target.closest('.imgContainer a'))
			// 		}

			// 	}
			// }

			// document.addEventListener('click', lightboxEventListener)

			// return () => {
			// 	document.removeEventListener('click', lightboxEventListener)
			// }
		}
	}, []);

	if(typeof window !== 'undefined'){
		setTimeout(() => {
			var arcs = document.querySelectorAll('.arc')
			arcs.forEach((arc) => {
				if(!arc.childElementCount){
					arc.closest('.arc-wrapper').classList.add('arc-wrapper--hidden')
				}
			})
		}, 400)
	}

	return (
		<article className="post">
			<div className="container">
				<Query query={POST_QUERY} variables={{'url': props.url}} >
					{({ loading, error, data }) => {
						if (loading) return <PostPlaceholder />
						if (error) return <E404 />

						return (
							<>
								<Helmet>
									{ data.getPost.postExtralibrary ?
										data.getPost.postExtralibrary.map((src, index) => {
											return(
												<script async key={`${index}`} src={`${src.url}`}></script>
											)
										})
										: null
									}
									{ data.getPost.additionalJS ? <script>{`${data.getPost.additionalJS}`}</script> : null }
									{ data.getPost.additionalCSS ? <style type="text/css">{`${data.getPost.additionalCSS}`}</style> : null }
									{
										data.getPost.seoTitle ? <title>{`${data.getPost.seoTitle} — ${SITE_NAME_SHORT}`}</title>
										: <title>{`${data.getPost.title} — ${SITE_NAME_SHORT}`}</title>
									}
									{
										data.getPost.seoTitle ? <meta name="twitter:title" content={`${data.getPost.seoTitle} — ${SITE_NAME_SHORT}`} />
										: <meta name="twitter:title" content={`${data.getPost.title} — ${SITE_NAME_SHORT}`} />
									}
									{
										data.getPost.seoTitle ? <meta name="og:title" content={`${data.getPost.seoTitle} — ${SITE_NAME_SHORT}`} />
										: <meta name="og:title" content={`${data.getPost.title} — ${SITE_NAME_SHORT}`} />
									}

									{ data.getPost.seoDescription ? <meta name="description" content={data.getPost.seoDescription} /> : null }
									{ data.getPost.seoDescription ? <meta name="twitter:description" content={data.getPost.seoDescription} /> : null }
									{ data.getPost.seoDescription ? <meta name="og:description" content={data.getPost.seoDescription} /> : null }

									{
										data.getPost.image ? <meta name="twitter:image:src" content={MEDIA_SRC + data.getPost.image} />
										: null
									}
									{
										data.getPost.image ? <meta name="og:image" content={MEDIA_SRC + data.getPost.image} />
										: null
									}
								</Helmet>

								<PostTeaser
									title={data.getPost.title}
									created={data.getPost.created}
								/>

								{data.getPost.translate &&
									<div className="post__note post__note--translate">
										<TranslateIcon width="45" height="37" aria-hidden="true" />
										<div dangerouslySetInnerHTML={{'__html': data.getPost.translate}}></div>
									</div>
								}

								{data.getPost.upd &&
									<div className="post__note post__note--update">
										<UpdateIcon width="63" height="51" aria-hidden="true" />
										<div dangerouslySetInnerHTML={{'__html': data.getPost.upd}}></div>
									</div>
								}

								{data.getPost.tldr &&
									<div className="post__note post__note--tldr">
										<TldrIcon width="86" height="26" aria-hidden="true" />
										<div dangerouslySetInnerHTML={{'__html': data.getPost.tldr}}></div>
									</div>
								}

								{data.getPost.postContent.map((content, index) => {
									return(
										<React.Fragment key={content.id}>
											{content.heading &&
												<h2 className="post__subheading">{content.heading}</h2>
											}
											<div className="post__contentBlock" dangerouslySetInnerHTML={{'__html': content.text}}></div>
											{content.code &&
												<PrismCode
													code={content.code}
													language={content.codeType}
													plugins={["post__contentCode", "line-numbers"]}
												/>
											}
											{index == 1 &&
												<div className="arc-wrapper">
													<AdSense.Google
														className='arc arc--articleRandom'
														client='ca-pub-3225394813524585'
														slot='1310895758'
														style={{ display: 'block', textAlign: 'center' }}
														format='fluid'
														layout='in-article'
													/>
												</div>
											}
										</React.Fragment>
									)
								})}

								{data.getPost.conclusion &&
									<div className="post__note post__note--conclusion">
										<ConclusionIcon width="45" height="37" aria-hidden="true" />
										<div dangerouslySetInnerHTML={{'__html': data.getPost.conclusion}}></div>
									</div>
								}

								<section className="post__links">
									{data.getPost.demo &&
										<a href={data.getPost.demo} className="post__links-item post__links-item--demo" target="_blank" rel="nofollow" aria-label="Ссылка на демо">
											<DemoIcon width="53" height="53" aria-hidden="true" />
										</a>
									}
									{data.getPost.github &&
										<a href={data.getPost.github} className="post__links-item post__links-item--github" target="_blank" rel="nofollow" aria-label="Ссылка на Github">
											<GithubIcon width="53" height="53" aria-hidden="true" />
										</a>
									}
								</section>

								<div className="arc-wrapper">
									<AdSense.Google
										className='arc arc--articleEnd'
										client='ca-pub-3225394813524585'
										slot='1310895758'
										style={{ display: 'block' }}
										format='auto'
										responsive='true'
									/>
								</div>

								<CommentAndShare url={props.url} />
							</>
						)
					}}
				</Query>
			</div>
		</article>
	)
}

export default Post

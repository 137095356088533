const MEDIA_SRC = 'https://batcave.webbeaver.ru/media/';
const API = 'https://batcave.webbeaver.ru/graphql/';

const SITE_URL = 'https://webbeaver.ru/';
const SITE_NAME_SHORT = 'WebBeaver.ru';
const SITE_NAME = 'WebBeaver.ru | Да, очередной блог';
const SITE_DESCRIPTION = 'Что-то о фронтенде и вёрстке.';
const SOCIAL_IMAGE = 'main-image.jpg';

export { API, MEDIA_SRC, SITE_NAME_SHORT, SITE_NAME, SITE_DESCRIPTION, SITE_URL, SOCIAL_IMAGE };

import React, { Component } from 'react';
import Prism from "prismjs"
import PrismJsx from 'prismjs/components/prism-jsx.min';

export class PrismCode extends React.Component {
	constructor(props) {
		super(props)
		this.ref = React.createRef()
	}
	componentDidMount() {
		if(typeof window !== 'undefined'){
			this.highlight()
		}
	}
	componentDidUpdate() {
		if(typeof window !== 'undefined'){
			this.highlight()
		}
	}
	highlight = () => {
		if (this.ref && this.ref.current) {
			Prism.highlightElement(this.ref.current)
		}
	}
	render() {
		const { code, plugins, language } = this.props
		return (
			<pre className={!plugins ? "" : plugins.join(" ")}>
				<code ref={this.ref} className={`language-${language}`}>
					{code.trim()}
				</code>
			</pre>
		)
	}
}

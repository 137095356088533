import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { MEDIA_SRC } from '../../helpers/const'
import { getDate } from '../../helpers/utils'

import './postTeaser.styl'


function PostTeaser({title, img, description, created, front}) {
	let date = getDate(created)

	return (
		<section className="teaser">
			{img &&
				<img className="teaser__img" src={MEDIA_SRC + img} alt={title} loading="lazy" />
			}

			{front ?
				<h2 className="teaser__title">{title}</h2>
			:
				<h1 className="teaser__title">{title}</h1>
			}

			<time className="teaser__date" dateTime={date.dateTime}>{date.dateLocal}</time>

			{description &&
				<div className="teaser__desc" dangerouslySetInnerHTML={{'__html': description}}></div>
			}
		</section>
	)
}

export default PostTeaser

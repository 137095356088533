import React, { Component } from 'react';

import './commentForm.styl'

import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

const ADD_COMMENT_MUTATION = gql`
	mutation ($commentName: String!, $commentText: String!, $commentUrl: String!) {
		createComment(name: $commentName, text: $commentText, postUrl: $commentUrl) {
			id
			created
			name
			text
		}
	}
`

class CommentForm extends Component {
	constructor(props){
		super(props)
		this.state = {
			commentName: "",
			commentText: "",
			commentUrl: this.props.url
		}
	}
	async componentDidMount() {
		const token = await this.props.googleReCaptchaProps.executeRecaptcha('homepage');
	}
	render() {
		const { commentName, commentText, commentUrl } = this.state
		return (
			<form className="commentForm">
				<label className="visually-hidden" htmlFor="commentName">Ваше имя</label>
				<input
					className="commentForm__input"
					type="text"
					name="commentName"
					id="commentName"
					placeholder="Имя"
					value={commentName}
					onChange={e => this.setState({ commentName: e.target.value })}
					required
				/>

				<label className="visually-hidden" htmlFor="commentText">Ваш комментарий</label>
				<textarea
					className="commentForm__input commentForm__input--textarea"
					name="commentText"
					id="commentText"
					placeholder="Комментарий"
					value={commentText}
					onChange={e => this.setState({ commentText: e.target.value })}
					required
				></textarea>

				<Mutation
					mutation={ADD_COMMENT_MUTATION}
					variables={{ commentName, commentText, commentUrl }}
				>
					{(addCommentMutation) => (
						<button className="commentForm__submit" onClick={(evt) => {
							evt.preventDefault()
							addCommentMutation()
								.then((response) => {
									this.setState({
										commentName: "",
										commentText: ""
									})
									if(typeof window !== 'undefined'){
										let emptyCommentsText = document.querySelector('.comments__empty')
										let commentsBlock = document.querySelector('.comments')

										if(emptyCommentsText){
											emptyCommentsText.remove()
										}

										let date = new Date(response.data.createComment.created)
										let dateLocal = date.toLocaleDateString("ru-RU", {
											year: "numeric",
											month: "long",
											day: "2-digit",
										})

										let newComment = document.createElement('section')
										newComment.classList.add('comments__item')
										newComment.classList.add('comments__item--new')

										let commentDate = date.getFullYear() + "-" + (date.getMonth() < 9 ? ("0" + (date.getMonth()+1)) : (date.getMonth()+1)) + "-" + date.getDate()

										newComment.innerHTML  = `
											<time class="comments__time" dateTime=${commentDate}>${dateLocal}</time>
											<div class="comments__wrapper">
												<div class="comments__name">${response.data.createComment.name}:</div>
												<div class="comments__text">${response.data.createComment.text}</div>
											</div>
										`

										commentsBlock.insertAdjacentElement('afterbegin', newComment)
									}
								})
						}}>Отправить</button>
					)}
				</Mutation>

			</form>
		)
	}
}

export default CommentForm

import React, { Component } from 'react';

import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import './commentAndShare.styl'

import Share from '../share'
import CommentForm from '../commentForm'
import Comments from '../comments'

const CommentFormWithReCaptcha = withGoogleReCaptcha(CommentForm);

class CommentAndShare extends Component {
	constructor(props){
		super(props)
		this.state = {
			showCommentForm: false
		}
		this.toggleCommentForm = this.toggleCommentForm.bind(this)
	}
	componentDidMount(){

	}

	toggleCommentForm(){
		this.setState({
			showCommentForm: !this.state.showCommentForm
		})
	}
	render() {
		return (
			<section className="commentAndShare">
				<div className="commentAndShare__wrapper">
					<button onClick={this.toggleCommentForm} className="commentAndShare__add" type="button">Добавить комментарий</button>
					<Share />
				</div>
				{this.state.showCommentForm &&
					<GoogleReCaptchaProvider reCaptchaKey="6LfJNpYUAAAAAChxsEXQe7YN6v70eiR4OMDx0YbI">
						<CommentFormWithReCaptcha url={this.props.url} />
					</GoogleReCaptchaProvider>
				}
				<Comments url={this.props.url} />
			</section>
		)
	}
}

export default CommentAndShare
